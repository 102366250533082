import './registration_step.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

export default function RegistrationStep({ position, className }) {
    return (
        <div className={className || ''}>
            <div className='d-flex d-flex-space-between registration-step'>
                <p className={`no-margin p-radial p-radial-first d-flex d-flex-align-items-center flex-one ${position > 1 ? 'active-line' : ''}`}>
                    <FontAwesomeIcon className='radial app-accent-color' icon={position > 1 ? faCheckCircle : faCircle} />
                </p>
                <p className={`no-margin p-radial p-radial-second d-flex d-flex-align-items-center flex-one ${position > 2 ? 'active-line' : ''}`}>
                    <FontAwesomeIcon className={`radial ${position > 1 ? 'app-accent-color' : 'app-faded-color app-faded-border-color'}`} icon={position > 2 ? faCheckCircle : faCircle} />
                </p>
                <p className='no-margin p-last-radial'>
                    <FontAwesomeIcon className={`last-radial ${position > 2 ? 'app-accent-color' : 'app-faded-color app-faded-border-color'}`} icon={position > 3 ? faCheckCircle : faCircle} />
                </p>
            </div>
            <div className='d-flex d-flex-space-between text-bold registration-text'>
                <p className={`no-margin ${position === 1 ? 'app-primary-color' : 'app-faded-color'}`}>
                    Contact details
                </p>
                <p className={`no-margin ${position === 2 ? 'app-primary-color' : 'app-faded-color'}`}>
                    Bank info
                </p>
                <p className={`no-margin ${position === 3 ? 'app-primary-color' : 'app-faded-color'}`}>
                    Confirmation
                </p>
            </div>
        </div>
    );
}

export const ShowStep = () => {
    return <RegistrationStep position={3} />
}