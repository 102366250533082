import { Link } from 'react-router-dom';
import './app_icon.css';

export default function AppIcon({ className, light }) {
	return (
		<p className={`text-bold-500 icon no-margin font-size-25 ${className || ''}`}>
			<Link to='/' className='app-icon-link'>
				<span className={light ? 'text-white' : 'app-primary-color'}>Crowd</span>
				<span className='app-accent-color'>EXPRESS</span>
			</Link>
		</p>
	);
}