import Navigation from "../navigation/navigation";

export default function Layout({ children }) {
    return (
        <div className='p-relative'>
            <Navigation />
            {children}
        </div>

    );
}