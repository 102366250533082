import { Link } from 'react-router-dom';
import './social_outlets.css';

import email from '../../assets/images/email.svg';
import twitter from '../../assets/images/twitter.svg';
import instagram from '../../assets/images/instagram.svg';
import facebook from '../../assets/images/facebook.svg';

export default function SocialOutlets({ className }) {
    return (
        <ul className={`d-flex social-outlet no-margin unstyle-ul ${className || ''}`}>
            <li><Link to='/'><img src={facebook} className='social-icon' alt='link to facebook page' /></Link></li>
            <li><Link to='/'><img src={instagram} className='social-icon' alt='link to instagram page' /></Link></li>
            <li><Link to='/'><img src={twitter} className='social-icon' alt='link to twitter page' /></Link></li>
            <li><Link to='/'><img src={email} className='social-icon' alt='shoot us a mail' /></Link></li>
        </ul>
    );
}