import AppButton from '../app_button/app_button';
import './form_container.css';


export default function FormContainer({ children, onSubmit, isBusy, onBackPress, twoWay, buttonName, className, extras }) {
    return (
        <form className={`form-container ${className || ''}`}>
            {children}
            <div className='d-flex j-content-center'>
                {
                    twoWay &&
                    <AppButton className='full-width form-button text-bold back-press-button' onClick={onBackPress} name='Back' />
                }
                <AppButton isBusy={isBusy} className={`full-width form-button text-bold app-primary-color ${twoWay ? 'next-press-button' : ''}`}
                    onClick={onSubmit} name={buttonName} />
            </div>
            
            {
                extras && extras()
            }
        </form>
    );
}
