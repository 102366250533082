import axios from "axios";
import { handleError } from "../error_handler/error_handler";
import ls from "../local/local";
import auth from './auth';
const token = ls.getTokenInLs();
axios.defaults.headers.common = auth.createHeaderToken(token);

const baseUrl = "/api/";
//const baseUrl = "http://localhost:56876/api/";

const verifyItem = (item) => {
  if (Array.isArray(item)) {
    return item.length;
  }
  return item;
};

const checkItemInLs = (name) => {
  //get intended item from local storage
  const item = ls.getItemInLs(name);
  return new Promise((resolve, reject) => {
    item && verifyItem(item.item) ? resolve(item.item) : reject(item.item);
  });
};

const get = (url, modelName, name) => {
  const item = checkItemInLs(name);
  //check if we have that item, if we do return it, otherwise, make an api call
  return item
    .then((data) => {
      return data;
    })
    .catch((e) => {
      return axios
        .get(baseUrl + url)
        .then((response) => {
          if (name) ls.persistItemInLS(name, response.data, 1);
          return response.data;
        })
          .catch((err) => {
              const message = handleError(err, modelName)
              throw new Error(message);
        });
    });
};


const update = (url, newObject, modelName, name) => {
  const item = checkItemInLs(name);
  return item
    .then((data) => data)
    .catch((e) => {
      return axios
        .put(baseUrl + url, newObject)
        .then((response) => {
          ls.persistItemInLS(name, response.data, 1);
          return response.data;
        })
        .catch((err) => {
            const message = handleError(err, modelName)
            throw new Error(message);
        });
    });
};

const create = (url, newObject, modelName, name) => {
    console.log(baseUrl+url, newObject)
  const item = checkItemInLs(name);
  return item
    .then((data) => data)
    .catch((e) => {
      return axios
        .post(baseUrl + url, newObject)
        .then((response) => {
          ls.persistItemInLS(name, response.data, 1);
          return response.data;
        })
        .catch((err) => {
            const message = handleError(err, modelName)
            throw new Error(message);
        });
    });
};

const remove = (url, modelName, name) =>
  axios
    .delete(baseUrl + url)
    .then((response) => {
      ls.removeItemFromLS(name);
      return response.data;
    })
    .catch((err) => {
        const message = handleError(err, modelName)
        throw new Error(message);
    });

const api = {
  get,
  update,
  create,
  remove,
};

export default api;