import AppBackground from '../app_background/app_background';
import congratulations from '../../assets/images/congratulations.svg';
import './congratulation.css';
import StoreButton from '../store_button/store_button';

export default function Congratulation() {
    return (
        <AppBackground
            route='/'
            className='congratulations'
            rightText='Back to home'
            rightTextClass='app-primary-color'>
            <div className='text-center d-flex flex-vertical j-content-center d-flex-align-items-center'>
                <p className='no-margin app-primary-color text-bold congratulation-title'>Congratulations in advance</p>
                <p className='no-margin app-primary-color congratulation-description'>A confirmation will be sent to your email address on approval</p>
                <img src={congratulations} alt='congratulations we registered you successfully' className='congratulation-image' />
                <p className='no-margin app-primary-color text-bold congrats-extra-head congratulation-extra'>Earn extra money on scheduled trip</p>
                <p className='no-margin app-primary-color congrats-extra-desc congratulation-extra'>Download crowdexpress app and get started</p>
                <StoreButton />
            </div>
        </AppBackground>
    );
}