import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthBackground } from '../../components/app_background/app_background';
import BankDetailStepper from '../../components/bank_detail_stepper/bank_detail_stepper';
import Congratulation from '../../components/congratulation/congratulation';
import ContactStepper from '../../components/contact_stepper/contact_stepper';
import { onUploadFile } from '../../helper/file_helper';
import RegisterForm from '../../components/register_form/register_form';
import RemoteMessage from '../../components/remote_message/remote_message';
import SummaryStepper from '../../components/summary_stepper/summary_stepper';
import { useFormFile } from '../../custom_hooks/use_form_file';
import { useInput } from '../../custom_hooks/use_input';
import auth from '../../side_effects/remote/auth';
import './register.css';

export default function Register() {
    const history = useHistory();
    const [isBusy, setIsBusy] = useState(false);
    const [position, setPosition] = useState(0);
    const [summary, setSummary] = useState({});
    
    //basic information props
    const emailProps = useInput('email', '');
    const telProps = useInput('tel', '');
    const locationProps = useInput('text', '');

    //contact details props
    const firstNameProps = useInput('text', '');
    const lastNameProps = useInput('text', '');
    const languageProps = useInput('text', '');
    const dobProps = useInput('date', '');
    const addressProps = useInput('text', '');
    const nextOfKinProps = useInput('text', '');
    const nextOfKinTelProps = useInput('tel', '');
    const passwordProps = useInput('password', '');

    

    const userImageProps = useFormFile(true);
    const idCardProps = useFormFile(true);

    

    //bank details props
    const accountNameProps = useInput('text', '');
    const accountNumberProps = useInput('text', '');
    const bankProps = useInput('text', '');


    const [status, setStatus] = useState(null);

    const statusMessageHandler = (success, message) => {
        setStatus({ success, message });
        setTimeout(() => {
            setStatus(null)
        }, 5000);
    }

    const isNotNullOrEmpty = (item) => !!(item && item.trim())

    const basicInfoIsVaild = () => {
        return (
            isNotNullOrEmpty(emailProps.value)
            && isNotNullOrEmpty(telProps.value)
            && isNotNullOrEmpty(locationProps.value)
        )
    }

    const contactInfoIsVaild = () => {
        return (
            isNotNullOrEmpty(firstNameProps.value)
            && isNotNullOrEmpty(lastNameProps.value)
            && isNotNullOrEmpty(languageProps.value)
            && isNotNullOrEmpty(dobProps.value)
            && isNotNullOrEmpty(addressProps.value)
            && isNotNullOrEmpty(nextOfKinProps.value)
            && isNotNullOrEmpty(nextOfKinTelProps.value)
            && isNotNullOrEmpty(passwordProps.value)
        )
    }

    const bankInfoIsVaild = () => {
        return (
            isNotNullOrEmpty(accountNameProps.value)
            && isNotNullOrEmpty(accountNumberProps.value)
            && isNotNullOrEmpty(bankProps.value)
        )
    }

    const advancePage = (index, title) => {
        const CONTACT_INFO = 1
        const BANK_INFO = 2
        const SUMMARY_INFO = 3
        const CONGRATULATION = 4
        switch (index) {
            case CONTACT_INFO:
                if (!basicInfoIsVaild()) return;
                break;
            case BANK_INFO:
                if (!contactInfoIsVaild()) return;
                break;
            case SUMMARY_INFO:
                if (!bankInfoIsVaild()) return;
                const summaryData = {
                    emailAddress: emailProps.value,
                    location: locationProps.value,
                    phone: telProps.value,
                    nexOfKinPhone: nextOfKinTelProps.value,
                    password: passwordProps.value,
                    confirmPassword: passwordProps.value,
                    firstName: firstNameProps.value,
                    surName: lastNameProps.value,
                    name: firstNameProps.value + ' ' + lastNameProps.value,
                    language: languageProps.value,
                    dob: dobProps.value,
                    address: addressProps.value,
                    nextOfKin: nextOfKinProps.value,
                    userSrc: userImageProps.src,
                    idSrc: idCardProps.src,
                    accountName: accountNameProps.value,
                    accountNumber: accountNumberProps.value,
                    bankName: bankProps.value
                }
                setSummary(summaryData);
                break;
            case CONGRATULATION:
                onRegister(index, title);
                return;
            default: return;
        }
        setPosition(index);
        history.push('/register/' + title);
    }



    const onRegister = async (index, title) => {
        let summaryDetail = summary;
        setIsBusy(true);
        if (userImageProps.file) {
            const picture = await onUploadFile(userImageProps.file);
            console.log({ picture });
            summaryDetail = { ...summaryDetail, picture }
        }
        if (idCardProps.file) {
            const idCard = await onUploadFile(idCardProps.file);
            console.log({ idCard })
            summaryDetail = { ...summaryDetail, idCard }
        }
        console.log(summaryDetail)
        auth.register(summaryDetail)
            .then(data => {
                setIsBusy(false);
                statusMessageHandler(true, "Successfully registered")
                setPosition(index);
                history.push('/register/' + title);
                console.log(data);
            })
            .catch(err => {
                setIsBusy(false);
                statusMessageHandler(false, err.message)
                console.log(err);
            })
    }

    return (
        <>
            {
                position === 0
                && (<AuthBackground
                    route='/login'
                    rightText='Log in'
                    light={true}
                    rightTextClass='text-white'
                    className='register-background'
                    authText='Travelling? Cover part of your travel expense'
                    mobileAuthText='Create account to get started'
                    authTextClass='text-bold-500 text-white'>
                    <RegisterForm
                        emailProps={emailProps} telProps={telProps}
                        locationProps={locationProps} onClick={() => advancePage(1, 'contact-details')} />
                </AuthBackground>)
                
            }
            {
                position === 1
                && <ContactStepper
                    firstNameProps={firstNameProps} lastNameProps={lastNameProps}
                    languageProps={languageProps} dobProps={dobProps} userImageProps={userImageProps}
                    addressProps={addressProps} nextOfKinProps={nextOfKinProps} idCardProps={idCardProps}
                    nextOfKinTelProps={nextOfKinTelProps} passwordProps={passwordProps}
                    onClick={() => advancePage(2, 'bank-details')} />
            }
            {
                position === 2
                && <BankDetailStepper
                    accountNameProps={accountNameProps} accountNumberProps={accountNumberProps}
                    onBackPress={() => setPosition(1)}
                    bankProps={bankProps} onClick={() => advancePage(3, 'summary')} />
            }
            {
                position === 3
                && <SummaryStepper child={() => <RemoteMessage status={status} />}
                    isBusy={isBusy} data={summary} onBackPress={() => setPosition(2)}
                    onClick={() => advancePage(4, 'congratulations')} />
            }

            {
                position === 4
                && <Congratulation />
            }
        </>
        
        
    );
}