import { useInput } from '../../custom_hooks/use_input';
import './form_input.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

export default function FormInput({ className, label, value, isActive, isValid, inputProps, onChange, type, error, }) {
    const isTypingReal = isActive || !!(value && value.trim())
    return (
        <div className={className || ''}>
            <div className='p-relative d-flex d-flex-align-items-center form-input'>
                <label className={`label-detail p-absolute ${isTypingReal ? 'started-typing' : 'no-typing'} text-uppercase`}>{label}</label>
                <AppInput className='p-absolute form-input-main' value={value} type={type} inputProps={inputProps} onChange={onChange} />
                {
                    isValid !== null &&
                    <FontAwesomeIcon
                        className={`p-absolute ${isValid ? 'vaild-check' : 'invalid-check'}`}
                        icon={isValid ? faCheckCircle : faTimesCircle} />
                }

            </div>
            {
                (isValid !== null && !isValid && error) &&
                <p className='error no-margin'>{error}</p>
            }
        </div>
    );
}

export const AppDropDown = ({ className, label, value, isValid, data, inputProps, onChange, type, error }) => {
    const isTypingReal = !!(value && value.trim())
    return (
        <div className={className || ''}>
            <div className='p-relative d-flex d-flex-align-items-center form-input'>
                <label className={`label-detail p-absolute ${isTypingReal ? 'started-typing' : 'no-typing'} text-uppercase`}>{label}</label>
                <AppInput className='p-absolute drop-down' value={value} type={type} inputProps={inputProps} onChange={onChange} />
                {
                    isValid !== null &&
                    <FontAwesomeIcon
                        className={`p-absolute ${isValid ? 'vaild-check' : 'invalid-check'} ${isTypingReal ? 'change-posiion' : ''}`}
                        icon={isValid ? faCheckCircle : faTimesCircle} />
                }
                <datalist onChange={onChange} value={value} id={inputProps.list}>
                    <option value=""></option>
                    {
                        data.map(item => <option key={item} value={item}>{item}</option>)
                    }

                </datalist>
            </div>
            {
                (isValid !== null && !isValid && error) &&
                <p className='error no-margin'>{error}</p>
            }
        </div>
    );
}

export const AppInput = ({ className, value, onChange, type, inputProps }) => {
    return <input className={`normalised line-height-1em app-primary-color input-detail ${className || ''}`} {...inputProps} value={value} type={type} onChange={onChange} />
}