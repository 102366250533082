import { useState } from 'react';

export const useInput = (type, val, validation) => {
    const [value, setValue] = useState(val);
    const [isValid, setIsValid] = useState(null);
    const [error, setError] = useState(null);
    const onChange = (e) => {
        const { target: { value } } = e;
        const defaultValidity = { valid: !!value ? true : null, message: '' }
        setValue(value);
        const { valid, message } = ((validation && validation(value)) || defaultValidity);
        setIsValid(valid);
        setError(message);
    }
    const reset = () => setValue('');
    return {
        type, value, onChange,
        reset, error, isValid
    }
}
