import AppButton from '../app_button/app_button';
import TwoRowLayout from '../two_row_layout/two_row_layout';
import './fourth_section.css';
import useApp from '../../assets/images/useApp.png';

export default function FourthSection() {
    return (
        <div>
            <div className='no-desktop-but-mobile pr-5'>
                <img className='download-image' src={useApp} alt='download the app and start sending' />
            </div>
            
            <div className='fourth-section app-padding-lr app-padding-tb p-relative'>
                <div className='download-detail'>
                    <TwoRowLayout
                        topText='Create orders in minutes'
                        topClass='font-size-25'
                        bottomClass='mobile-font-size'
                        bottomText="Quickly connect with CrowdExpress agents 
                                    and other verified road users using the route of your parcel's destination.
                                    Conveniently deliver your item without waiting another 24 hours." />
                    <AppButton name='download app' className='text-white text-capitalize app-download' />
                </div>
                <img className='p-absolute no-mobile absolute-props download-image' src={useApp} alt='download the app and start sending' />
            </div>
        </div>
        
    );
}