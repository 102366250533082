import TwoRowLayout from '../two_row_layout/two_row_layout';
import './three_row_layout.css';

export default function ThreeRowLayout({ src, alt, imgClass, topText, topClass, bottomText, bottomClass, twoRowClass, className }) {
    const twoRowProps = { topText, topClass, bottomText, bottomClass, className: twoRowClass };
    return (
        <div className={className || ''}>
            <img src={src} alt={alt} className={`${imgClass || ''} three-row-img`} />
            <TwoRowLayout {...twoRowProps} />
        </div>
    );
}
