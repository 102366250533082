import './fifth_section.css';
import safe from '../../assets/images/safe.svg';
import earPiece from '../../assets/images/earPiece.svg';
import smiley from '../../assets/images/smiley.svg';
import ThreeRowLayout from '../three_row_layout/three_row_layout';

export default function FifthSection() {
    return (
        <div className='d-flex fifth-section d-flex-space-between app-padding-lr app-padding-tb'>
            <ThreeRowLayout
                src={safe}
                alt='safe and convenient'
                topText='Safe and convenient'
                bottomText='Our platform uses trusted and verified travelers to make that important delivery on your behalf' />

            <ThreeRowLayout
                src={smiley}
                alt='Happy users'
                topText='Happy users'
                bottomText='We allow our community lead happier lives by removing the logistics bottlnecks' />

            <ThreeRowLayout
                src={earPiece}
                alt='Always there for you'
                topText='Always there for you'
                bottomText='Reach a traveller without having to wait another 24-hours to make that delivery order' />
        </div>
    );
}