import axios from "axios";
import { handleError } from "../error_handler/error_handler";

//const baseUrl = "http://localhost:56876/api/users/";
const baseUrl = "/api/users/";

const createHeaderToken = (token) => { return { authorization: `bearer ${token || ''}` }; }

const persistUserInLS = (user) => {
    if (localStorage) {
        localStorage.setItem("user", JSON.stringify(user));
    }
};

const removeUserFromLS = () => {
    if (localStorage) {
        localStorage.removeItem("user");
    }
};

const login = (loginCredentials) =>
    axios
        .post(baseUrl + "login", loginCredentials)
        .then((response) => {
            console.log(response);
            persistUserInLS(response.data);
            console.log(response.data);
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err, "User")
            throw new Error(message);
        });

const register = (registerCredentials) =>
    axios
        .post(baseUrl + "register", registerCredentials)
        .then((response) => {
            console.log("register works");
            persistUserInLS(response.data);
            return response.data;
        })
        .catch((err) => {
            const message = handleError(err, "User")
            throw new Error(message);
        });

export const logout = () => {
    removeUserFromLS();
};
const auth = {
    createHeaderToken,
    login,
    register,
};

export default auth;
