import './image_upload.css';
import user from '../../assets/images/user.svg';

export default function ImageUpload({ fileProps, className, clickable, imgText }) {
    
    return (
        <div className={`${className || ''} image-upload-area d-flex d-flex-align-items-center`}>
            <CustomInputFile clickable={clickable} fileProps={fileProps} className='j-content-center'>
                <img src={user} alt='user profile selector' />
            </CustomInputFile>
            <p className='no-margin text-bold app-primary-color img-text-upload'>{imgText || 'Upload profile picture'}</p>
            
        </div>
    );
}


export const CustomInputFile = ({ className, fileProps, children, clickable }) => {
    const { src = null } = fileProps;
    return (
        <div className={`clickable-area d-flex p-relative d-flex-align-items-center ${className || ''}`} style={{ backgroundImage: `url(${src || ''})` }}>
            {!src && children}
            {clickable && <input type='file' className='custom-file-input p-absolute' {...fileProps} />}
        </div>
    );
}