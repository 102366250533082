import AppIcon from '../app_icon/app_icon';
import './navigation.css';
import navigation from '../../assets/images/navigation.svg';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StoreButton from '../store_button/store_button';

export default function Navigation() {
	const { pathname } = useLocation();
	const [navOpen, setNavOpen] = useState(null);

	const onNavMenuClick = () => {
		setNavOpen(true);
	}

	const onBodyClick = (e, value) => {
		console.log({value})
		setNavOpen(value);
		e.stopPropagation();
	}

	useEffect(() => {
		if (navOpen) {
			document.body.style.overflow = 'hidden';
		}
		else {
			return () => document.body.style.overflow = null;
		}
		return () => document.body.style.overflow = null;
	}, [navOpen])

	//close nav on new route
	useEffect(() => {
		setNavOpen(null);
	}, [pathname])

	return (
		<div className='no-desktop-but-mobile-flex navigation d-flex-space-between app-padding-lr'>
			<AppIcon />
			<button className='unstyle-button c-pointer' onClick={onNavMenuClick}>
				<img src={navigation} className='navigation-icon' alt='navigation button' />
			</button>
			{
				navOpen !== null &&
				<NavigationItem
					className={navOpen ? 'nav-open' : 'nav-close'}
					onClick={onBodyClick} />
			}
			
		</div>
	);
}


const NavigationItem = ({ className, onClick }) => {
	return (
		<div className={`p-fixed whole-area ${className || ''}`} onClick={(e) => onClick(e, false)}>
			<div onClick={(e) => onClick(e, true)} className='p-absolute app-primary-bg-color main-portion'>
				<div className='d-flex content flex-vertical p-absolute d-flex-space-between'>
					<ul className='unstyle-ul text-white'>
						<li className='nav-rider'>Become a<br /> CrowdExpress<br /> agent</li>
						<li className='nav-links'><Link to='/login'>Login</Link></li>
						<li className='nav-links'><Link to='/register'>Sign Up</Link></li>
					</ul>
					<StoreButton className='nav-store-button' orientation='vertical' />
				</div>
			</div>
		</div>
	);
}