import FifthSection from '../components/fifth_section/fifth_section';
import FirstSection from '../components/first_section/first_section';
import Footer from '../components/footer/footer';
import FourthSection from '../components/fourth_section/fourth_section';
import SecondSection from '../components/second_section/second_section';
import SixthSection from '../components/sixth_section/sixth_section';
import ThirdSection from '../components/third_section/third_section';
import './home.css';

export default function Home() {
    return (
        <div className='p-relative'>
            <FirstSection />
            <SecondSection />
            <ThirdSection />
            <FourthSection />
            <FifthSection />
            <SixthSection />
            <Footer />
        </div>
    );
}
