import FormContainer from '../form_container/form_container';
import FormInput, { AppDropDown } from '../form_input/form_input';
import ImageUpload, { CustomInputFile } from '../image_upload/image_upload';
import StepperLayout from '../stepper_layout/stepper_layout';
import photo from '../../assets/images/photo.svg';
import './contact_stepper.css';

export default function ContactStepper(
    {
        onClick, onBackPress, className, firstNameProps, lastNameProps,
        languageProps, dobProps, userImageProps, addressProps, nextOfKinProps,
        nextOfKinTelProps, passwordProps, idCardProps,
    }) {
    const languages = ["Igbo", "Hausa", "Yoruba", "Efik"];
    return (
        <div className={className || ''}>
            <StepperLayout position={1}
                title='Personal information and contact details'
                description='Only your picture, first name, and phone number will be visible to clients during the booking'>
                <FormContainer onSubmit={onClick} onBackPress={onBackPress} buttonName='Next' className='contact-form'>
                    <div className='no-desktop-but-mobile'>
                        <p className='no-margin app-primary-color registration-title'>Personal information and contact details</p>
                        <p className='no-margin app-primary-color registration-description'>
                            Only your picture, first name, and phone number will be visible to clients during the booking
                        </p>
                    </div>
                    <ImageUpload fileProps={userImageProps} className='contact-image' clickable={true} />
                    <FormInput className='contact-element' label='First Name' {...firstNameProps} />
                    <FormInput className='contact-element' label='Last Name' {...lastNameProps} />
                    <div className='language-cum-dob'>
                        <AppDropDown className='contact-element language' data={languages} {...languageProps} label='language' inputProps={{ list: 'languages' }} />
                        <FormInput className='contact-element dob' label='date of birth' isActive={true} {...dobProps} />
                    </div>
                    
                    <FormInput className='contact-element' label='Permanent Address' {...addressProps} />
                    <FormInput className='contact-element' label='Next of Kin' {...nextOfKinProps} />
                    <FormInput className='contact-element' label='Next of Kin Phone No' {...nextOfKinTelProps} />
                    <FormInput className='contact-element' label='Password' {...passwordProps} />
                    <CustomInputFile className='upload-national-id' clickable={true} fileProps={idCardProps}>
                        <div className='d-flex d-flex-space-between'>
                            <p className='no-margin upload-bottom-text text-uppercase'>upload your national id card</p>
                            <img src={photo} alt='upload national id' />
                        </div>
                        
                    </CustomInputFile>
                </FormContainer>
            </StepperLayout>
        </div>
    );
}