import './app_button.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function AppButton({ name, className, onClick, type, isBusy }) {
    return (
        <button className={`${className || ''} app-accent-bg-color app-button`} onClick={onClick} type={type || 'button'}>
            {isBusy ? <FontAwesomeIcon icon={faSpinner} spin={true} /> : name}
        </button>
    );
}

