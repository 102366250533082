import { Link } from 'react-router-dom';
import { useInput } from '../../custom_hooks/use_input';
import FormContainer from '../form_container/form_container';
import FormInput from '../form_input/form_input';
import './login_form.css'

export default function LoginForm({ className, emailProps, passwordProps }) {
    return (
        <FormContainer buttonName='Login' className={`${className || ''} login-form`} extras={() => (
            <div className='d-flex flex-vertical d-flex-space-between flex-one'>
                <p className='no-padding top-info-register no-desktop-but-mobile text-center'>
                    Don't have an account? <Link to='/register'>Sign up</Link>
                </p>
                
            </div>

        )}>
            <p className='form-title text-bold font-size-25 no-mobile app-primary-color'>Log In</p>
            <FormInput className='input-element' label='Email' {...emailProps} />
            <FormInput className='input-element' label='Password' {...passwordProps} />
        </FormContainer>
    );
}