import FormContainer from '../form_container/form_container';
import StepperLayout from '../stepper_layout/stepper_layout';
import './summary_stepper.css';
import ImageUpload from '../image_upload/image_upload';

export default function SummaryStepper({ onClick, child, onBackPress, isBusy, className, data }) {
    return (
        <div className={className || ''}>
            <StepperLayout position={3}
                title='Summary'
                description='Please verify your details'>
                <FormContainer isBusy={isBusy} onBackPress={onBackPress} onSubmit={onClick} buttonName='Submit' twoWay={true} className='contact-form'>
                    <div className='no-desktop-but-mobile'>
                        <p className='no-margin app-primary-color registration-title'>Summary</p>
                        <p className='no-margin app-primary-color registration-description'>
                            Please verify your details
                        </p>
                    </div>
                    <ImageUpload className='summary-image' clickable={false} fileProps={{ src: data.userSrc }} imgText={data.name} />
                    <div className='no-mobile'>
                        <SummaryItem title='language' description={data.language} />
                        <SummaryItem title='date of birth' description={data.dob} />
                        <SummaryItem title='permanent address' description={data.address} />
                        <SummaryItem title='next of kin' description={data.nextOfKin} />
                        <SummaryItem title='next of kin phone number' description={data.nextOfKinPhone} />
                        <SummaryItem title='password' description='***************' />
                        <SummaryItem title='id card' isImage={true} description={data.idSrc} />
                        <SummaryItem title='account name' description={data.accountName} />
                        <SummaryItem title='account number' description={data.accountNumber} />
                        <SummaryItem title='bank' description={data.bankName} />
                    </div>
                    <div className='no-desktop-but-mobile'>
                        <div className='d-flex'>
                            <SummaryItem className='flex-one' title='full name' description={data.name} />
                            <SummaryItem className='flex-one' title='date of birth' description={data.dob} />
                        </div>
                        <SummaryItem className='padding-mobile-20' title='permanent address' description={data.address} />
                        <div className='d-flex padding-mobile-20'>
                            <SummaryItem className='flex-one' title='language' description={data.language} />
                            <SummaryItem className='flex-one' title='next of kin' description={data.nextOfKin} />
                        </div>

                        <div className='d-flex padding-mobile-20'>
                            <SummaryItem className='flex-one' title='account name' description={data.accountName} />
                            <SummaryItem className='flex-one' title='next of kin phone number' description={data.nextOfKinPhone} />
                        </div>

                        <div className='d-flex padding-mobile-20'>
                            <SummaryItem className='flex-one' title='account number' description={data.accountNumber} />
                            <SummaryItem className='flex-one' title='bank' description={data.bankName} />
                        </div>
                        <SummaryItem className='padding-mobile-20' title='id card' isImage={true} description={data.idSrc} />
                    </div>
                </FormContainer>
                {child && child()}
            </StepperLayout>
        </div>
    );
}

const SummaryItem = ({ title, className, description, isImage }) => {
    return (
        <div className={`d-flex text-left summary-item ${className || ''}`}>
            <p className='no-margin text-capitalize summary-title'>{title}</p>
            {
                isImage
                    ? <img src={description} alt='summary detail for id upload' />
                    : <p className='no-margin summary-description flex-one app-primary-color'>{description}</p>
            }
        </div>
    );
}