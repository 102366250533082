import { AuthBackground } from '../../components/app_background/app_background';
import LoginForm from '../../components/login_form/login_form';
import { useInput } from '../../custom_hooks/use_input';
import './login.css';

export default function Login() {
    const emailProps = useInput('email', '');
    const passwordProps = useInput('password', '');

    return (
        <AuthBackground
            route='/register'
            rightText='Sign up'
            rightTextClass='app-primary-color'
            className='login-background'
            authText='Welcome Back!'
            authTextClass='text-bold-500 app-primary-color'>
            <LoginForm emailProps={emailProps} passwordProps={passwordProps} />
        </AuthBackground>
    );
}