import { useHistory } from 'react-router-dom';
import AppButton from '../app_button/app_button';
import './second_section.css';

export default function SecondSection() {
    const history = useHistory();
    return (
        <div className='second-section app-padding-lr d-flex d-flex-align-items-center d-flex-space-between'>
            <div>
                <span className='app-primary-color text-bold-500 font-size-43 reduce-letter-space-082'>Earn extra money while on the go</span><br />
                <span className='app-primary-color description text-light mobile-font-size reduce-letter-space-082'>Make money from trips you are already scheduled to take</span>
            </div>
            <div>
                <AppButton onClick={() => history.push('/register')} name='become an agent' className='text-uppercase text-bold-700 font-size-as-parent' />
            </div>
            
        </div>
    );
}
