import { useInput } from '../../custom_hooks/use_input';
import FormContainer from '../form_container/form_container';
import FormInput from '../form_input/form_input';
import StepperLayout from '../stepper_layout/stepper_layout';
import './bank_detail_stepper.css';

export default function BankDetailStepper({ onClick, onBackPress, className, accountNameProps, accountNumberProps, bankProps }) {
    return (
        <div className={className || ''}>
            <StepperLayout position={2}
                title='Enter your banking details'
                description='This is secure. Your account details is needed to make payments to you'>
                <FormContainer onBackPress={onBackPress} onSubmit={onClick} buttonName='Next' twoWay={true} className='contact-form'>
                    <div className='no-desktop-but-mobile'>
                        <p className='no-margin app-primary-color registration-title'>Enter your banking details</p>
                        <p className='no-margin app-primary-color registration-description'>
                            This is secure. Your account details is needed to make payments to you
                        </p>
                    </div>
                    <FormInput className='contact-element' label='Account Name' {...accountNameProps} />
                    <FormInput className='contact-element' label='Account Number' {...accountNumberProps} />
                    <FormInput className='contact-element' label='Bank' {...bankProps} />
                </FormContainer>
            </StepperLayout>
        </div>
    );
}