import { Route, Switch } from "react-router-dom";
import Layout from "./components/layout/layout";
import { ScrollToTop } from "./components/scroll_to_top/scroll_to_top";
import Home from "./pages/home";
import Login from "./pages/login/login";
import Register from "./pages/register/register";

export default function App() {
    return (

        <ScrollToTop>
            <Layout>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path='/login' component={Login} />
                    <Route path='/register' component={Register} />
                </Switch>
            </Layout>

        </ScrollToTop>
    );
}

