import { Link } from 'react-router-dom';
import { useInput } from '../../custom_hooks/use_input';
import FormContainer from '../form_container/form_container';
import FormInput from '../form_input/form_input';
import './register_form.css'

export default function RegisterForm({ className, emailProps, telProps, locationProps, onClick }) {

    return (
        <FormContainer onSubmit={onClick} extras={() => (
            <div className='d-flex flex-vertical d-flex-space-between flex-one'>
                <p className='no-padding top-info-register no-desktop-but-mobile text-center'>
                    Already have an account? <Link to='/login'>Login</Link>
                </p>
                <p className='no-margin extra-info'>
                    By Submitting this form, you agree and accept company's
                    <span> Terms and conditions</span> and <span>Privacy policy</span> and allow company to contact you
                    by email and sms for transactions and notifications
                </p>
            </div>

        )} buttonName='Sign up' className={`${className || ''} register-form`}>
            <p className='form-title text-bold font-size-25 app-primary-color no-mobile'>Create account to get started</p>
            <FormInput className='input-element' label='Email' {...emailProps} />
            <div className='d-flex'>
                <FormInput className='input-element readonly-tel' value='+234' isValid={null} inputProps={{ readOnly: 'readonly' }} />
                <FormInput className='input-element fill-tel' label='Phone' {...telProps} />
            </div>
            <FormInput className='input-element' label='Location' {...locationProps} />
        </FormContainer>
    );
}