import file from "../side_effects/remote/file"


export const onUploadFile = (fileData) => {
    const formData = new FormData()
    formData.append('file', fileData)
    return file.addFile(formData)
        .then(response => response.name)
        .catch(err => console.error(err))
}

export const onEditFile = (image, fileData) => {
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('oldImage', image)
    return file.editFile(formData)
        .then(response => response.name)
        .catch(err => console.error(err))
}

export const onDeleteFile = (fileData) => {
    return file.deleteFile(fileData)
        .then(response => {
            if (response.status === '204') {
                return true;
            }
        })
        .catch(err => console.error(err))
}
