import { Link } from 'react-router-dom';
import AppIcon from '../app_icon/app_icon';
import './app_background.css';

export default function AppBackground({ children, className, rightText, rightTextClass, route, light }) {
    return (
        <div className={`${className || ''} auth-background d-flex`}>
            <div className=' app-padding-lr d-flex flex-vertical flex-one flex-vertical-mobile remove-padding-mobile app-padding-starter-tb'>
                <div className='d-flex d-flex-align-items-center d-flex-space-between nav-section no-mobile'>
                    <AppIcon light={light} />
                    <Link className='nav-link text-bold' to={route || '/'}><span className={`${rightTextClass || ''} right-text`}>{rightText}</span></Link>
                </div>
                {children}
            </div>
            
        </div>
    );
}

export const AuthBackground = ({ className, rightText, mobileAuthText, rightTextClass, authText, authTextClass, children, route, light }) => {
    return (
        <AppBackground rightText={rightText} rightTextClass={rightTextClass || ''} light={light} route={route} className={`auth-background ${className || ''}`}>
            <div className='d-flex d-flex-align-items-center d-flex-space-between auth-content-area flex-one'>
                <div className={`auth-child`}>
                    <p className={` auth-text big-text-description ${authTextClass || ''} no-mobile`}>{authText}</p>
                    <p className={`auth-child auth-text ${authTextClass || ''} no-desktop-but-mobile`}>{mobileAuthText || authText}</p>
                </div>
                
                <div className='auth-child auth-child-bottom'>
                    {children}
                </div>

            </div>
        </AppBackground>
    );
}