import SubscriptionInput from '../subscription_input/subscription_input';
import './sixth_section.css';

export default function SixthSection() {
    return (
        <div className='d-flex d-flex-justify-center sixth-section flex-vertical app-primary-bg-color app-padding-lr app-padding-tb'>
            <p className='text-white text-center no-margin font-size-32 text-light'>Subscribe to our newsletter and stay updated</p>
            <div className='small-text-newsletter app-primary-color text-center font-size-10 text-light'>
                <p className='no-margin mobile-font-size small-text'>Get the latest information about logistics and technology business while learning</p>
                <p className='no-margin mobile-font-size small-text last-small-text'>travellers pattern by subscribing to our newsletter</p>
            </div>
            <SubscriptionInput className='half-width' />
        </div>
    );
}