import { useState } from "react";
import AppButton from "../app_button/app_button";
import api from '../../side_effects/remote/api';
import './subscription_input.css';
import RemoteMessage from "../remote_message/remote_message";

export default function SubscriptionInput({ className }) {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null);
    const [isBusy, setIsBusy] = useState(false);

    const statusMessageHandler = (success, message) => {
        setStatus({ success, message });
        setTimeout(() => {
            setStatus(null)
        }, 5000);
    }

    const onClick = () => {
        if (email && email.trim()) {
            setIsBusy(true);
            api.create('newsletters', { email }, "Newsletter")
                .then(data => {
                    console.log(data)
                    setIsBusy(false);
                    statusMessageHandler(true, 'Successfully subscribed');
                })
                .catch(err => {
                    console.log(err);
                    setIsBusy(false);
                    console.log(err.message)
                    statusMessageHandler(false, err.message);
                })
        }
    }

    return (
        <div className='full-width d-flex flex-vertical j-content-center d-flex-align-items-center'>
            <div className={`${className || ''} d-flex subsciption-input-container`}>
                <input value={email} onChange={({ target: { value } }) => setEmail(value)} placeholder='Enter your email address' className='subscription-input full-width fixed-height-50' type='email' />
                <AppButton name='submit' isBusy={isBusy} onClick={onClick} className='text-capitalize text-white app-button fixed-height-50 sub-override no-side-left-side-border negative-margin-left-2 text-bold-700 font-size-as-parent' />
                
            </div>
            <RemoteMessage status={status} />
        </div>

    );
}

