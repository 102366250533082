import RegistrationStep from '../registration_step/registration_step';
import './stepper_layout.css';

export default function StepperLayout({ position, stepperClass, className, title, description, children }) {
    return (
        <div className={`${className || ''} stepper-layout d-flex flex-vertical text-center app-padding-lr app-padding-tb`}>
            <div className='padded-area'>
                <RegistrationStep position={position} className={stepperClass || ''} />
            </div>
            <div className='no-mobile'>
                <p className='no-margin app-primary-color registration-title'>{title}</p>
                <p className='no-margin app-primary-color registration-description'>{description}</p>
            </div>
            
            <div className='form-content-stepper'>
                {children}
            </div>
        </div>
    );
}