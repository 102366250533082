import BlurryText from '../blurry_text/blurry_text';
import BoldText from '../bold_text/bold_text';
import './two_row_layout.css';

export default function TwoRowLayout({ topText, topClass, bottomText, bottomClass, className }) {
    return (
        <div className={className || ''}>
            <p className={`${topClass || ''} no-margin top-text`}><BoldText className='text-bold' text={topText} /></p>
            <p className={`${bottomClass || ''} no-margin mobile-font-size bottom-text`}><BlurryText text={bottomText} /></p>
        </div>
    );
}


