import './third_section.css';
import clearance from '../../assets/images/clearance.svg';
import ThreeRowLayout from '../three_row_layout/three_row_layout';

export default function ThirdSection() {
    return (
        <div className='d-flex third-section d-flex-space-between app-padding-lr'>
            <ThreeRowLayout
                src={clearance}
                alt='Place an order'
                topText='Place an order'
                bottomClass='mobile-font-size'
                bottomText='Select from a pool of crowdexpress agents to personalise your delivery to the locations you want them delivered' />

            <ThreeRowLayout
                src={clearance}
                alt='Best prices'
                topText='Best Prices'
                bottomClass='mobile-font-size'
                bottomText='Get the best prices for each delivery. Our delivery price points are far cheaper than everyday courier service business' />

            <ThreeRowLayout
                src={clearance}
                alt='Easy to use'
                topText='Easy to use'
                bottomClass='mobile-font-size'
                bottomText='Our platform is simple to use. Simply download the app and start making that important delivery' />
        </div>
    );
}