import { useState } from 'react';
import getReadableFile from '../helper/get_reader_file';


export const useFormFile = (withSrc) => {
    const [file, setFile] = useState('');
    const [src, setSrc] = useState(null);
    const onChange = (e) => {
        const file = e.target.files[0]
        setFile(file)
        if (withSrc) {
            const reader = getReadableFile(e.target);
            if (reader) {
                reader.onloadend = function (_) {
                    setSrc(reader.result);
                }
            }
        }
        
    };
    return { file, onChange, src }
}
