import { Link } from 'react-router-dom';
import AppIcon from '../app_icon/app_icon';
import BlurryText from '../blurry_text/blurry_text';
import BoldText from '../bold_text/bold_text';
import SocialOutlets from '../social_outlets/social_outlets';
import StoreButton from '../store_button/store_button';
import './footer.css';

  

export default function Footer() {
    return (
        <div className='app-padding-lr font-size-15'>
            <div className='d-flex first-layer d-flex-space-between app-padding-tb'>
                <div className='footer-left d-flex flex-vertical'>
                    <AppIcon className='footer-icon' />
                    <div className='store-button-cum-description'>
                        <StoreButton className='store-button-container' />
                        <p className='footer-description mobile-font-size no-margin'>
                            <BlurryText text='Personalise important deliveries to ' /><br />
                            <BoldText className='text-bold' text='clients, family and friends ' />
                            <BlurryText text='using our verified travellers with CrowdExpress ' />
                        </p>
                    </div>
                    
                </div>
                <div className='footer-right single-column d-flex'>
                    <div className='company-section'>
                        <BoldText className='text-bold' text='Company' />
                        <ul className='footer-links unstyle-ul'>
                            <li><Link to='/'><BlurryText text='About' /></Link></li>
                            <li><Link to='/'><BlurryText text='Contact' /></Link></li>
                            <li><Link to='/login'><BlurryText text='Login' /></Link></li>
                            <li><Link to='/register'><BlurryText text='Register' /></Link></li>
                        </ul>
                    </div>
                    <div className='quick-link-section'>
                        <BoldText className='text-bold' text='Quick Links' />
                        <ul className='footer-links unstyle-ul'>
                            <li><Link to='/'><BlurryText text='Privacy Policy' /></Link></li>
                            <li><Link to='/'><BlurryText text='Terms and Services' /></Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <SocialOutlets className='footer-right no-desktop-but-mobile-flex' />
            <hr className='footer-divider app-primary-bg-color' />
            <div className='d-flex copyright-info d-flex-space-between d-flex-align-items-center'>
                <p className='footer-left no-margin'>
                    <BlurryText className='text-bold-400' text='&copy; 2020 CrowdExpress Technology' />
                </p>
                <SocialOutlets className='footer-right no-mobile' />
            </div>
        </div>
    );
}